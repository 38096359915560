<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-text">
        <h2>Download</h2>
        <p>
          {{ $t("download01") }}
        </p>
        <p>
          {{ $t("download02") }}
        </p>
        <div class="p-grid">
          <div class="p-col-12 box-img">
            <a href="https://slutguy.itch.io/quest-for-the-dream-girl">
              <Button
                :label="$t('btn_itchio')"
                icon="pi pi-globe"
                iconPos="left"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-img">
        <img alt="Martha" src="../assets/martha1.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>