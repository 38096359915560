<template>
  <Menubar :model="items">
    <template #start>
      <img alt="Slutguy logo" src="./assets/slutguy.png" />
    </template>
    <template #end>
      <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
      <Dropdown
        v-model="selectedLanguage"
        :options="languages"
        optionLabel="name"
        placeholder="Select a Language" />
    </template>
  </Menubar>
    <router-view></router-view>
  <footer>
    <p>© 2021 Slutguy Development. All Rights Reserved</p>
  </footer>
</template>

<script>

export default {
  data() {
    return {
      items: [
        {
          label: "Home",
          icon: "pi pi-fw pi-home",
          to: '/home',
        },
        {
          label: "News",
          icon: "pi pi-fw pi-info-circle",
          to: '/news',
        },
        {
          label: "Download",
          icon: "pi pi-fw pi-download",
          to: '/download',
        },
        {
          label: "Play Online",
          icon: "pi pi-fw pi-play",
          url: 'https://quest-web.onrender.com',
        },
        {
          label: "FAQ",
          icon: "pi pi-fw pi-question-circle",
          to: "/faq",
        },

        {
          label: "Social",
          icon: "pi pi-fw pi-users",
          items: [
            {
              label: "Discord",
              icon: "pi pi-fw pi-discord",
              url: "https://discord.gg/nDDAgAQArq"
            },
            {
              label: "Twitter",
              icon: "pi pi-fw pi-twitter",
              url: "https://twitter.com/SlutGuyDotDev"
            },
            {
              label: "Youtube",
              icon: "pi pi-fw pi-youtube",
              url: "https://www.youtube.com/channel/UC5hEXLJjYA_lQYlcNs23k7A"
            },
            {
              label: "Patreon",
              icon: "pi pi-fw pi-dollar",
              url: "https://www.patreon.com/slutguy"
            },
            {
              label: "Reddit",
              icon: "pi pi-fw pi-reddit",
              url: "https://www.reddit.com/r/questforthedreamgirl/"
            },
          ],
        },
        {
          label: "Contribute",
          icon: "pi pi-fw pi-thumbs-up",
          to: '/contribute',
        },
      ],
      selectedLanguage: "",
      languages: [
        { name: "English", code: "en" },
        { name: "Portuguese", code: "pt" },
        { name: "Spanish", code: "es" },
        { name: "Dutch", code: "nl" },
        { name: "French", code: "fr" },
        { name: "Russian", code: "ru" },
      ],
    };
  },
  methods: {
    agecheck_dialog() {
      this.$confirm.require({
        message:
          "The contents of this webpage may be inappropriate if you are under 18 years old (or 21 years old in some countries). Are you sure you want to proceed?",
        header: "Warning",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          //callback to execute when user confirms the action
        },
        reject: () => {
          window.location.href = 'notage.html';
        },
      });
    },
  },
  watch: {
    selectedLanguage() {
      this.$i18n.locale = this.selectedLanguage.code;
    }
  },
  mounted() {
    this.agecheck_dialog();
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
  background-color: #20262e;
  color: #aeb2b8;
}

body {
  margin: 0;
}

footer {
  width: 100%;
  height: 5rem;
  background-color: #343e4d;
  color: #aeb2b8;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.box-img {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.box-text {
  text-align: justify;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
h2 {
  text-align: center;
}
.text-white {
  color: #aeb2b8;
}

.text-gray {
  color: #929090;
}

a {
  text-decoration: none;
}
</style>
