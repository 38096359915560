<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-text">
        <h2>FAQ</h2>
        <p>
          {{ $t("faq01") }}
        </p>

        <Accordion>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q01") }}</span>
            </template>
            <span class="text-gray">{{ $t("a01") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q02") }}</span>
            </template>
            <span class="text-gray">{{ $t("a02") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q03") }}</span>
            </template>
            <span class="text-gray">{{ $t("a03") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q04") }}</span>
            </template>
            <span class="text-gray">{{ $t("a04") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q05") }}</span>
            </template>
            <span class="text-gray">{{ $t("a05") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q06") }}</span>
            </template>
            <span class="text-gray">{{ $t("a06") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q07") }}</span>
            </template>
            <span class="text-gray">{{ $t("a07") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q08") }}</span>
            </template>
            <span class="text-gray">{{ $t("a08") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q09") }}</span>
            </template>
            <span class="text-gray">{{ $t("a09") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q10") }}</span>
            </template>
            <span class="text-gray">{{ $t("a10") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q11") }}</span>
            </template>
            <span class="text-gray">{{ $t("a11") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q12") }}</span>
            </template>
            <span class="text-gray">{{ $t("a12") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q13") }}</span>
            </template>
            <span class="text-gray">{{ $t("a13") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q14") }}</span>
            </template>
            <span class="text-gray">{{ $t("a14") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q15") }}</span>
            </template>
            <span class="text-gray">{{ $t("a15") }}</span>
          </AccordionTab>

          <AccordionTab>
            <template #header>
              <i class="pi pi-question-circle"></i> &nbsp;
              <span>{{ $t("q16") }}</span>
            </template>
            <span class="text-gray">{{ $t("a16") }}</span>
          </AccordionTab>
          
        </Accordion>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-img">
        <img alt="Elisa" src="../assets/elisa2.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>