<template>
  <img alt="Quest for the Dream Girl" src="../assets/quest.png" />

  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-text">
        <h2>Quest for the Dream Girl</h2>
        <p>
          {{ $t("home01") }}
        </p>
        <p>
          {{ $t("home02") }}
        </p>
        <p>
          {{ $t("home03") }}
        </p>
        <p>
          {{ $t("home04") }}
        </p>
        <p>
          {{ $t("home05") }}
        </p>
        <p>
          {{ $t("home06") }}
        </p>
        <div class="p-grid">
          <div class="p-col-12 p-md-12 p-lg-4 box-img">
            <router-link to="/contribute">
                        <Button
              :label="$t('btn_contribute')"
              icon="pi pi-dollar"
              iconPos="left"
            />
            </router-link>
          </div>
          <div class="p-col-12 p-md-12 p-lg-4 box-img">
            <router-link to="/download">
            <Button
              :label="$t('btn_download')"
              icon="pi pi-download"
              iconPos="left"
            />
            </router-link>
          </div>
          <div class="p-col-12 p-md-12 p-lg-4 box-img">
            <a href="https://discord.gg/nDDAgAQArq">
            <Button
              :label="$t('btn_discord')"
              icon="pi pi-discord"
              iconPos="left"
            />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-img">
        <img alt="Gina" src="../assets/gina1.png" />
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-img">
        <img alt="Rita" src="../assets/ritinha1.png" />
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-text">
        <h2>{{ $t("char_dev") }}</h2>
        <div class="p-grid p-formgrid p-text-center">
          <div class="p-field p-col-12 p-md-4">
            <h5>Bianca</h5>
            <Knob
              v-model="bianca"
              value-template="{value}%"
              value-color="#ffb933"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>Gina</h5>
            <Knob
              v-model="gina"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>{{ $t("rita") }}</h5>
            <Knob
              v-model="ritinha"
              value-template="{value}%"
              value-color="#ffb933"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>Elisa</h5>
            <Knob
              v-model="elisa"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>Martha</h5>
            <Knob
              v-model="martha"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>Eliana</h5>
            <Knob
              v-model="eliana"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>Foxie</h5>
            <Knob
              v-model="foxie"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>Luna</h5>
            <Knob
              v-model="luna"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>Velvet</h5>
            <Knob
              v-model="velvet"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>

          <div class="p-field p-col-12 p-md-4">
            <h5>Pudding</h5>
            <Knob
              v-model="pudding"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>

          <div class="p-field p-col-12 p-md-4">
            <h5>Rebecca</h5>
            <Knob
              v-model="rebecca"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>

          <div class="p-field p-col-12 p-md-4">
            <h5>Aria</h5>
            <Knob
              v-model="aria"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>
        </div>

        <h2>{{ $t("languages") }}</h2>
        <div class="p-grid p-formgrid p-text-center">
          <div class="p-field p-col-12 p-md-4">
            <h5>{{ $t("english") }}</h5>
            <Knob
              v-model="ingles"
              value-template="{value}%"
              value-color="#9edaa8"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>{{ $t("portuguese") }}</h5>
            <Knob
              v-model="portugues"
              value-template="{value}%"
              value-color="#9edaa8"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>{{ $t("spanish") }}</h5>
            <Knob
              v-model="espanhol"
              value-template="{value}%"
              value-color="#9edaa8"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>{{ $t("dutch") }}</h5>
            <Knob
              v-model="dutch"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>{{ $t("french") }}</h5>
            <Knob
              v-model="french"
              value-template="{value}%"
              value-color="#f19ea6"
              readonly
            />
          </div>
          <div class="p-field p-col-12 p-md-4">
            <h5>{{ $t("russian") }}</h5>
            <Knob
              v-model="russian"
              value-template="{value}%"
              value-color="#9edaa8"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-3"></div>
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-img">
        <Galleria
          :value="images"
          :responsiveOptions="responsiveOptions"
          :numVisible="7"
          :circular="true"
          style="max-width: 800px"
        >
          <template #item="slotProps">
            <img
              :src="slotProps.item.itemImageSrc"
              :alt="slotProps.item.alt"
              style="width: 100%; display: block"
            />
          </template>
          <template #thumbnail="slotProps">
            <img
              :src="slotProps.item.thumbnailImageSrc"
              :alt="slotProps.item.alt"
              style="display: block"
            />
          </template>
        </Galleria>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-3"></div>
  </div>
</template>

<script>
import PhotoService from "../service/PhotoService";

export default {
  data() {
    return {
      bianca: 70,
      gina: 30,
      ritinha: 50,
      elisa: 30,
      martha: 40,
      eliana: 10,
      foxie: 20,
      luna: 50,
      velvet: 10,
      rebecca: 10,
      aria: 20,
      pudding: 10,
      ingles: 100,
      portugues: 95,
      espanhol: 75,
      dutch: 2,
      french: 15,
      russian: 100,
      images: null,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "960px",
          numVisible: 4,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 2,
        },
      ],
    };
  },
  galleriaService: null,
  created() {
    this.galleriaService = new PhotoService();
  },
  mounted() {
    window.scrollTo(0, 0)
    this.galleriaService.getImages().then((data) => (this.images = data));
  },
};
</script>


\