<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-text">
        <h2>{{ $t("pagenotfound") }}</h2>
        <p>
          {{ $t("pagenotfound01") }}
        </p>
        <p>
          {{ $t("pagenotfound02") }}
        </p>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-img">
        <img alt="Quest for the Dream Girl" src="../assets/bianca1.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0)
  },
};
</script>