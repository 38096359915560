<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-text">
        <h2>{{ $t("contribute") }}</h2>
        <p>
          {{ $t("contribute01") }}
        </p>
        <p>
          {{ $t("contribute02") }}
        </p>
        <p>
          {{ $t("contribute03") }}
        </p>
        <p>
          {{ $t("contribute04") }}
        </p>
        <p>
          {{ $t("contribute05") }}
        </p>
        <div class="p-grid">
          <div class="p-col-12 p-md-12 p-lg-4 box-img">
            <a href="https://www.patreon.com/slutguy">
            <Button
              :label="$t('btn_patreon')"
              icon="pi pi-dollar"
              iconPos="left"
            /></a>
          </div>
          <div class="p-col-12 p-md-12 p-lg-4 box-img">
            <a href="https://slutguy.itch.io/quest-for-the-dream-girl">
            <Button
              :label="$t('btn_itchio')"
              icon="pi pi-globe"
              iconPos="left"
            /></a>
          </div>
          <div class="p-col-12 p-md-12 p-lg-4 box-img">
            <a href="https://discord.gg/nDDAgAQArq">
            <Button
              :label="$t('btn_discord')"
              icon="pi pi-discord"
              iconPos="left"
            />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="box-img">
        <img alt="Rita" src="../assets/ritinha2.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0)
  },
};
</script>
