<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
      <div class="box-text">
        <h1>{{ $t("news") }}</h1>
      </div>
      <div class="box-text">
        <Card>
          <template #title> Version 0.6.4 released! </template>
          <template #subtitle> February, 2023 </template>
          <template #content>
            <p>We corrected a bug that on some occasions Luna appeared during the day, awake, in the crypt.</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> Version 0.6.3 released! </template>
          <template #subtitle> February, 2023 </template>
          <template #content>
            <p>Luna's Picture 5 was not showing when using it at inventory, fixed!</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> Version 0.6.2 released! </template>
          <template #subtitle> January, 2023 </template>
          <template #content>
            <p>Fixed bugs in Guy's house when upgrading the farm.</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> Version 0.6.1 released </template>
          <template #subtitle> January, 2023 </template>
          <template #content>
            <p>Fixed some bugs in Aria's cave that made her story unfeasible.</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> Version 0.6.0 released! </template>
          <template #subtitle> January, 2023 </template>
          <template #content>
            <p>Hey guys! We finally have a new version!</p>
            <p>What is inside the box?</p>
            <p> - We now have a Combat system.</p>
            <p> - We rebalanced the payment to the father (it will increase after some events).</p>
            <p> - We implemented the farm evolution system (build new buildings, expand fields) through the construction office in Ameiro Village.</p>
            <p> - We include some NPCs to give some hints about the quests.</p>
            <p> - Now you will be able to meet Aria, our slime girl (until now she was running away).</p>
            <p> - We will introduce Amanda, a new girl linked to Luna's story, giving the guy the possibility, in the future, to date two girls at the same time.</p>
            <p> - Tons of bug fixes!</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title011") }} </template>
          <template #subtitle> {{ $t("date011") }} </template>
          <template #content>
            <p>{{ $t("content011_01") }}</p>
            <p>{{ $t("content011_02") }}</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title010") }} </template>
          <template #subtitle> {{ $t("date010") }} </template>
          <template #content>
            <img alt="Eliana" src="../assets/v5.png" />
            <p>{{ $t("content010_01") }}</p>
            <p>{{ $t("content010_02") }}</p>
            <p>{{ $t("content010_03") }}</p>
            <p>{{ $t("content010_04") }}</p>
            <p>{{ $t("content010_05") }}</p>
            <p>{{ $t("content010_06") }}</p>
            <p>{{ $t("content010_07") }}</p>
            <p>{{ $t("content010_08") }}</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title009") }} </template>
          <template #subtitle> {{ $t("date009") }} </template>
          <template #content>
            <img alt="Eliana" src="../assets/androidvelvet.png" />
            <p>{{ $t("content009_01") }}</p>
            <p>{{ $t("content009_02") }}</p>
            <p>{{ $t("content009_03") }}</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title007") }} </template>
          <template #subtitle> {{ $t("date007") }} </template>
          <template #content>
            <img alt="Eliana" src="../assets/girls030.png" />
            <p>{{ $t("content007_01") }}</p>
            <p>{{ $t("content007_02") }}</p>
            <p>{{ $t("content007_03") }}</p>
            <p>{{ $t("content007_04") }}</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title008") }} </template>
          <template #subtitle> {{ $t("date007") }} </template>
          <template #content>
            <img alt="Eliana" src="../assets/foxieriver.png" />
            <p>{{ $t("content008_01") }}</p>
            <p>{{ $t("content008_02") }}</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title007") }} </template>
          <template #subtitle> {{ $t("date007") }} </template>
          <template #content>
            <img alt="Eliana" src="../assets/girls030.png" />
            <p>{{ $t("content007_01") }}</p>
            <p>{{ $t("content007_02") }}</p>
            <p>{{ $t("content007_03") }}</p>
            <p>{{ $t("content007_04") }}</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title006") }} </template>
          <template #subtitle> {{ $t("date006") }} </template>
          <template #content>
            <img alt="Eliana" src="../assets/foxie-eliana.png" />
            <p>{{ $t("content006_01") }}</p>
            <p>{{ $t("content006_02") }}</p>
            <p>{{ $t("content006_03") }}</p>
            <p>{{ $t("content006_04") }}</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title005") }} </template>
          <template #subtitle> {{ $t("date002") }} </template>
          <template #content>
            <img alt="Eliana" src="../assets/elianaxmas.png" />
            <p>
              {{ $t("content005_01") }}
            </p>
            <p>{{ $t("content005_02") }}</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title004") }} </template>
          <template #subtitle> {{ $t("date002") }} </template>
          <template #content>
            <p>
              {{ $t("content004_01") }}
            </p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title003") }} </template>
          <template #subtitle> {{ $t("date002") }} </template>
          <template #content>
            <p>
              {{ $t("content003_01") }}
            </p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title002") }} </template>
          <template #subtitle> {{ $t("date002") }} </template>
          <template #content>
            <img alt="Rita" src="../assets/poster1.png" />
            <p>{{ $t("content002_01") }}</p>
          </template>
        </Card>
      </div>
      <div class="box-text">
        <Card>
          <template #title> {{ $t("title001") }} </template>
          <template #subtitle> {{ $t("date001") }} </template>
          <template #content>
            <p>{{ $t("content001_01") }}</p>
          </template>
        </Card>
      </div>
    </div>
  </div>
  <p></p>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>