import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';

import { createI18n } from 'vue-i18n'
import en from './translations/en.json'
import pt from './translations/pt-BR.json'
import es from './translations/es.json'
import nl from './translations/nl.json'
import fr from './translations/fr.json'
import ru from './translations/ru.json'

const languages = {
    en: en,
    pt: pt,
    es: es,
    nl: nl,
    fr: fr,
    ru: ru,
  }
  const messages = Object.assign(languages)
  
  const i18n = createI18n({
      locale: 'en',
      fallbackLocale: 'en',
      messages,
  })

import TheHome from './components/TheHome.vue';
import TheNews from './components/TheNews.vue';
import TheDownload from './components/TheDownload.vue';
import TheFaq from './components/TheFaq.vue';
import TheContribute from './components/TheContribute.vue';
import NotFound from './components/NotFound.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/home'},
        { path: '/home', component: TheHome}, // alias: '/' },
        { path: '/news', component: TheNews },
        { path: '/download', component: TheDownload },
        { path: '/faq', component: TheFaq },
        { path: '/contribute', component: TheContribute },
        { path: '/:notFound(.*)*', component: NotFound }
    ]
}) ;


import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css';       //theme
import 'primevue/resources/primevue.min.css';                //core css
import 'primeicons/primeicons.css';                           //icons
import 'primeflex/primeflex.css';

// PrimeVue services:
import ConfirmationService from 'primevue/confirmationservice';

// PrimeVue components:
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Card from 'primevue/card';
import ConfirmDialog from 'primevue/confirmdialog';
import Dropdown from 'primevue/dropdown';
import Knob from 'primevue/knob';
import Menubar from 'primevue/menubar';
import Galleria from 'primevue/galleria';

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(PrimeVue);
app.use(ConfirmationService);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Button', Button);
app.component('Card', Card);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Dropdown', Dropdown);
app.component('Knob', Knob);
app.component('Menubar', Menubar);
app.component('Galleria', Galleria);

app.mount('#app');
